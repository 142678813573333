var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{staticClass:"my-courses-view",attrs:{"title":"Мои мероприятия","is-loading":_vm.isLoading}},[(_vm.currentLessons && _vm.currentLessons.length>0)?[_c('v-divider',{staticClass:"mb-4"}),_c('subtitle',[_vm._v("Текущие занятия:")]),_c('v-list',{attrs:{"color":"transparent","dense":""}},_vm._l((_vm.currentLessons),function(ref){
var curLesson = ref[0];
var currCourse = ref[1];
return _c('v-list-item',{key:curLesson.lessonId,attrs:{"to":{
          name: 'course-lesson',
          params: {courseId: currCourse.courseId, lessonId: curLesson.lessonId}
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-chip',{staticClass:"mr-2"},[_c('schedule-time',{attrs:{"lesson":curLesson}})],1),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.courseName(currCourse)))])],1)],1),_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(" "+_vm._s(curLesson.lessonDurationHumanized)+" "),_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v(" mdi-clock-outline ")])],1)],1)],1)}),1),(!_vm.nearestLesson)?_c('v-divider',{staticClass:"mb-8 mt-4"}):_c('div',{staticClass:"mb-4"})]:_vm._e(),(_vm.nearestLesson)?[_c('v-divider',{staticClass:"mb-4"}),_c('subtitle',[_vm._v("Ближайшее занятие")]),_c('v-list',{attrs:{"color":"transparent","dense":""}},[_c('v-list-item',{attrs:{"to":{
          name: 'course-lesson',
          params: {courseId: _vm.nearestLesson[1].courseId,lessonId:_vm.nearestLesson[0].lessonId}
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-chip',{staticClass:"mr-2"},[_c('schedule-time',{attrs:{"lesson":_vm.nearestLesson[0]}})],1),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.courseName(_vm.nearestLesson[1])))])],1)],1),_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(" "+_vm._s(_vm.nearestLesson[0].lessonDurationHumanized)+" "),_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v(" mdi-clock-outline ")])],1)],1)],1)],1),_c('v-divider',{staticClass:"mb-8 mt-4"})]:_vm._e(),_c('subtitle',[_vm._v("Все курсы")]),_c('v-row',{staticClass:"mb-2"},[_c('v-col',[_c('v-btn-toggle',{model:{value:(_vm.showOnly),callback:function ($$v) {_vm.showOnly=$$v},expression:"showOnly"}},[_c('v-btn',{attrs:{"small":"","value":"all"}},[_vm._v("Все")]),_c('v-btn',{attrs:{"small":"","value":_vm.CourseStatus.active}},[_vm._v("Только активные")])],1)],1),_c('v-col',{staticClass:"text-right"},[(_vm.isModifyCoursesAllowed)?_c('v-btn',{attrs:{"to":{name: 'courses-new'},"color":"primary"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Добавить курс ")],1):_vm._e()],1)],1),_vm._l((_vm.coursesWithPolygons),function(ref){
        var course = ref.course;
return _c('v-card',{key:course.courseId,staticClass:"mb-8"},[_c('v-toolbar',{attrs:{"dark":"","tabs":"","flat":"","color":"primary"}},[_c('v-toolbar-title',[(course.status===_vm.CourseStatus.canceled)?_c('v-chip',{staticClass:"mr-2",attrs:{"color":"red lighten-2","small":""}},[_vm._v(" Не активный ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.courseName(course))+" ")],1),_c('v-spacer'),(_vm.isModifyCoursesAllowed)?[_c('v-btn',{attrs:{"icon":"","to":{name: 'course-edit',params:{courseId:course.courseId}}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]:_vm._e()],2),_c('v-card-title'),_c('v-card-subtitle',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-4"},[_vm._v("Дата начала: "+_vm._s(course.startDate))]),_c('div',[_vm._v("Завершение: "+_vm._s(course.finishDate))])]),_c('v-card-text',[_c('subtitle',{attrs:{"mb":0}},[_vm._v("Расписание занятий")]),_c('lessons-list',{attrs:{"course":course,"lessons":course.lessons,"limit":3,"start-day":_vm.currentDay,"dense":""}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"to":{name:'course-schedule',params:{courseId:course.courseId}},"color":"primary","text":""}},[_vm._v(" Все занятия ("+_vm._s(course.lessons.length)+") ")]),_c('v-btn',{attrs:{"to":{name: 'course-students',params:{courseId: course.courseId}},"color":"primary","text":""}},[_vm._v(" Участники ")])],1)],1)}),_c('router-view',{attrs:{"name":"dialogs"},on:{"course-updated":_vm.handleCourseUpdated,"close":_vm.handleDialogClose}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }