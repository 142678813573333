































import {
  Prop,
  Vue,
  Component,
} from 'vue-property-decorator';
import PageHeader1 from '@/components/PageHeader1.vue';
import { BreadcrumbsItem } from '@/types/BreadcrumbsItem';

@Component({
  components: { PageHeader1 },
})
export default class Page extends Vue {
  @Prop({
    type: String,
    default: '',
  }) title!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  isLoading!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hideTitle!: boolean;

  @Prop({
    type: Array,
    default: () => ([]),
  })
  breadcrumbsItems!: BreadcrumbsItem[];
}
