





























































































































































































import { Component, Inject, Vue } from 'vue-property-decorator';
import { ApiFacade } from '@/services/ApiFacade';
import PageHeader1 from '@/components/PageHeader1.vue';
import { AppStore } from '@/store/types/AppStore';
import { Polygon } from '@/models/entities/Polygon';
import { Course } from '@/models/entities/Course';
import Subtitle from '@/components/Subtitle.vue';
import Page from '@/components/Page.vue';
import ScheduleTime from '@/views/courses/components/ScheduleTime.vue';
import { CourseStatus, LessonStatus } from '@/types/Statuses';
import { Lesson } from '@/models/entities/Lesson';
import LessonsList from '@/views/courses/components/LessonsList.vue';
import { NotificationsService } from '@/services/NotificationService';

interface CoursePolygonPair {
  polygon: Pick<Polygon, 'name'>;
  course: Course;
}

@Component({
  components: {
    LessonsList,
    ScheduleTime,
    Page,
    Subtitle,
    PageHeader1,
  },
})
export default class MyCoursesListView extends Vue {
  @Inject() apiFacade!: ApiFacade;
  @Inject() appStore!: AppStore;
  @Inject() notify!: NotificationsService;
  courses: Course[] = [];
  isLoading: boolean = true;
  LessonStatus = LessonStatus;
  CourseStatus = CourseStatus;
  showOnly: CourseStatus.active | 'all' = CourseStatus.active;
  currentDay: Date = new Date();
  currentDayTimer: number | null = null;

  async mounted() {
    this.currentDayTimer = setInterval(() => {
      this.currentDay = new Date();
    }, 60_000);
    await this.loadCoursesList();
  }

  destroyed() {
    if (this.currentDayTimer) {
      clearInterval(this.currentDayTimer);
    }
  }

  handleDialogClose() {
    this.$router.push({ name: 'courses' });
  }

  handleCourseUpdated(newCourse: Course) {
    const updatedCourseIndex = this.courses.findIndex((c) => c.courseId === newCourse.courseId);
    if (updatedCourseIndex === -1) {
      this.loadCoursesList();
      this.notify.addNotification({ text: 'Событие добавлено' });
    } else {
      Vue.set(this.courses, updatedCourseIndex, newCourse);
      this.notify.addNotification({ text: 'Событие обновлено' });
    }
  }

  private async loadCoursesList() {
    try {
      this.isLoading = true;
      this.courses = await this.apiFacade.fetchCourses() || [];
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  get isModifyCoursesAllowed(): boolean {
    return Boolean(this.appStore.getters.profile?.isSuperAdmin);
  }

  get polygons() {
    return this.appStore.getters.availablePolygons;
  }

  get polygonsMappedByIriId() {
    return this.appStore.getters.availablePolygonsMappedByIriDd;
  }

  get currentLessons(): [Lesson, Course][] {
    const now = new Date();
    const lessons = this.activeCourses
      .map((c) => {
        return c.lessons
          .filter((l) => {
            return l.lessonDateDt <= now && l.lessonFinishDt > now && l.status === LessonStatus.active;
          })
          .map((l) => {
            return [l, c] as [Lesson, Course];
          });
      })
      .flat(1);
    return lessons;
  }

  get activeCourses(): Course[] {
    return this.courses
      .filter((c) => {
        return c.status === CourseStatus.active;
      });
  }

  get nearestLesson(): [Lesson, Course] | null {
    const now = new Date();
    const lessons = this.activeCourses
      .map((c) => {
        return c.lessons
          .filter((l) => {
            return l.lessonDateDt > now && l.status === LessonStatus.active;
          })
          .map((l) => {
            return [l, c] as [Lesson, Course];
          });
      })
      .flat(1)
      .sort((a, b) => {
        if (a[0].lessonDateDt === b[0].lessonDateDt) {
          return 0;
        }
        return a[0].lessonDateDt > b[0].lessonDateDt ? 1 : -1;
      });

    return lessons?.[0] || null;
  }

  get coursesWithPolygons(): CoursePolygonPair[] {
    const coursesPolygonsPairs = this.courses
      .map<CoursePolygonPair>((course) => {
        return {
          course,
          polygon: { name: course.polygon.name || '' },
        };
      })
      .filter((i) => i.polygon?.name)
      .filter((i) => {
        if (this.showOnly === 'all') {
          return true;
        }
        const isStatusSuitable: boolean = i.course.status === this.showOnly;
        if (!isStatusSuitable) {
          return false;
        }
        const isThereAnyNotExpiredLesson: boolean = i.course.lessons.findIndex((l) => {
          return l.lessonDateDt >= this.currentDay;
        }) !== -1;
        return isThereAnyNotExpiredLesson;
      });

    return coursesPolygonsPairs;
  }

  courseName(course: Course) {
    return `${course.courseName} / ${course.polygon.name}`;
  }
}
